import React, { useState, useEffect } from 'react'
import { useDeudas } from '../context/DeudasContext'
import { Container, Row, Col } from 'react-bootstrap';

export const FacturaNoSincronizados = () => {
    const {user, facturas, enviarFacturaNoSincSAP, loadingLogin } = useDeudas();
    const [arregloSeleccionado, setArregloSeleccionado] = useState([]);

    const ver = ()=>{
        console.log(facturas)
        console.log(arregloSeleccionado)
    }

    const manejadorCambiosCheckbox = (event)=> {

        const { name, checked } = event.target;        
        const arrayTodoSeleccionado = [];
        const arraySeleccionado = [];

        if(name === "allSelect"){
            const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");

            if (checked) {
                checkBoxSelected.forEach((item, indice) => {     
                    checkBoxSelected[indice].checked = true;
                    arrayTodoSeleccionado.push(item.name);                    
                });
                setArregloSeleccionado(arrayTodoSeleccionado)

            }else{
                checkBoxSelected.forEach((item, indice) => {     
                    checkBoxSelected[indice].checked = false                       
                });
                setArregloSeleccionado([])
            }                        
        }else{
            const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");
            const checkBoxSelectedAll = document.getElementById("allSelect");
            let isAllSelect = true;

            if (checked) {

                checkBoxSelected.forEach((item, indice) => {                     
                    if (item.checked == false) {
                        isAllSelect = false
                    }else{
                        arraySeleccionado.push(item.name)
                    }
                });                
                
                if (isAllSelect) {
                    checkBoxSelectedAll.checked = true;
                    setArregloSeleccionado(arraySeleccionado);
                }else{ //NO ESTA SELECCIONADO TODO                    
                    checkBoxSelectedAll.checked = false;
                    setArregloSeleccionado([...arraySeleccionado]);
                }
            }else{
                checkBoxSelectedAll.checked = false;                
                setArregloSeleccionado(arregloSeleccionado.filter( id => id !== name ))
            }                        
        }        

      //console.log(event)
      
      //console.log(checked)
      //console.log(event.target.value.nroFactura)
    }

    const manejadorEnviarSAP = () => {  
        if (arregloSeleccionado.length > 0) {

            const arregloFiltrado = [];
            for (let index = 0; index < arregloSeleccionado.length; index++) {
                const element = arregloSeleccionado[index];             
                const objFiltrado = facturas.find((data) => data.id == element)  
                //console.log(objFiltrado)             
                arregloFiltrado.push(objFiltrado);

            }   

            const data ={
                "clientes": arregloFiltrado,
                "usuario": user.usuario,
                "sesion": user.sesion
            }
             enviarFacturaNoSincSAP(data);
              
            
           // console.log(arregloFiltrado)       
           // console.log(data)  
           // enviarClienteFacturaSAP(arregloFiltrado);
        }         
    }

    useEffect(() => {
        if(!loadingLogin){
            const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");
            checkBoxSelected.forEach((item, indice) => {     
                checkBoxSelected[indice].checked = false                       
            });
            setArregloSeleccionado([])
        }
       
        
      },[loadingLogin])
 

    return (
    <>
    <div className="card shadow" style={{margin: '1%', marginRight:'2%',width: '100%'}}>
    <div className="card-header "> <Row>
        <Col> <p className='title'>GESTIONAR FACTURAS</p></Col>
        </Row>
    </div>
    <div className='d-flex justify-content-between align-items-center m-4 px-1 tabla_scroll'> 
                <div className='form-check'>
                    <input type='checkbox' className='form-check-input'
                        name='allSelect'
                        id='allSelect'
                        disabled={false}
                        value=""
                        onChange={manejadorCambiosCheckbox}
                    />
                    <label className='form-check-label ms-2'>Seleccionar Todo</label>
                </div>                         

                <button onClick={()=>{manejadorEnviarSAP()}} className='btn btn-info text-white my-1'><i className='fas fa-paper-plane'></i> Enviar Pagos SAP</button>
            </div>
            {
          loadingLogin && (
              <div className="d-flex justify-content-center">
                  <div className="loader2"></div>
                  <div>Enviando pagos</div>
              </div> 
          )
        }
    <div className="card-body" style={{textAlign:'left'}}>
    <div className="table-responsive mt-2" >
        <table className="table table-hover">
        <thead>
            <tr>
            <th>Enviar</th>
            <th>DocEntryFac</th>
            <th>Nro.Factura</th>
            <th>Cliente</th>
            <th>Nombre Factura</th>
            <th>NIT Factura</th>
            <th>Fecha</th>
            <th>Monto</th>
            </tr>
        </thead>
        <tbody>
        {facturas == null ? null : (facturas.map( (factura, indice) => (
            <tr key={indice}>
                <td>
                    <input type='checkbox' className='checkBoxSelect'
                            id={factura.id}
                            disabled={false}
                            value={factura.id}
                            name={factura.id}
                            onChange={manejadorCambiosCheckbox}
                            />
                </td> 
                <td> {factura.nroDocumentoSAPFactura} </td>                       
                <td> {factura.nroFactura} </td>                       
                <td> {factura.nombreCliente}</td>                        
                <td> {factura.nombreFactura} </td>
                <td> {factura.nitFactura}</td>
                <td>{new Date(factura.fechaTransaccion).getDate()}/{new Date(factura.fechaTransaccion).getMonth()+1}/{new Date(factura.fechaTransaccion).getFullYear()}</td>
                <td> {factura.montoMulta} </td>   
            </tr>
        )))}
        </tbody>
        </table>
    </div>
    </div>
    </div>
    
    </>
    )
}